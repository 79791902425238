<template>
    <div class="page orderDetail">
        <page-header :bread-crumb="true" :back-button="true" :sync="true" />
        <div class="wrap" v-loading="loading">
            <div v-if="canRefund" class="refundMod">
                <el-button type="danger" @click="refundEvent">
                    发起退款
                </el-button>
            </div>
            <div class="module">
                <div class="mtit">交易信息：</div>
                <div class="moduleW" flex="dir:left box:first">
                    <div class="left">
                        <p class="item">订单号：{{ tradeInfo.out_trade_no }}</p>
                        <p class="item">
                            支付系统订单号：{{ tradeInfo.pay_id }}
                        </p>
                        <p class="item">
                            创建时间：{{
                                tradeInfo.create_time | $unixTimeToDateTime
                            }}
                        </p>
                        <p class="item">
                            更新时间：{{
                                tradeInfo.update_time | $unixTimeToDateTime
                            }}
                        </p>
                        <p class="item">买家信息：{{ tradeInfo.buyer_info }}</p>
                        <p class="item">
                            卖家信息：{{ tradeInfo.seller_info }}
                        </p>
                        <p class="item">会员id：{{ tradeInfo.merchant_id }}</p>
                        <p class="item">
                            回调地址：{{ tradeInfo.callback_url }}
                        </p>
                    </div>
                    <div class="right">
                        <p class="item">三方订单号：{{ tradeInfo.trade_no }}</p>
                        <p class="item">交易记录自增id：{{ tradeInfo.id }}</p>
                        <p class="item">
                            总金额：{{ tradeInfo.total_fee | $toyun }}
                        </p>
                        <p class="item">
                            状态：{{ payStatusList[tradeInfo.status] }}
                        </p>
                        <p class="item">
                            支付类型：{{ payTypeList[tradeInfo.pay_type] }}
                        </p>
                        <p class="item">订单详情：{{ tradeInfo.attach }}</p>
                        <p class="item">订单描述：{{ tradeInfo.subject }}</p>
                        <p class="item">
                            渠道：{{ getChannelName(tradeInfo.channel_id) }}
                        </p>
                        <p class="item">
                            业务方：{{ getClientName(tradeInfo.client_id) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="module">
                <div class="mtit">查询日志：</div>
                <div class="moduleW">
                    <p class="item">{{ queryLog }}</p>
                </div>
            </div>
            <div class="module">
                <div class="mtit">通知信息：</div>
                <div class="moduleW">
                    <p class="item">{{ notifyInfo }}</p>
                </div>
            </div>
            <div class="module">
                <div class="mtit">退款记录：</div>
                <div class="moduleW">
                    <div class="tableList">
                        <!-- 表格部分 -->
                        <el-table
                            :data="refundInfo"
                            class="table-box"
                            max-height="645"
                            :header-cell-style="{
                                background: '#F5F5F5',
                                color: '#606266',
                            }"
                        >
                            <el-table-column
                                prop="refund_id"
                                label="系统退款订单号"
                                min-width="180"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="refund_trade_no"
                                label="三方退款订单号"
                                min-width="180"
                            >
                            </el-table-column>
                            <el-table-column label="业务方" min-width="120">
                                <template slot-scope="{ row }">
                                    <span>
                                        {{ getClientName(row.client_id) }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column label="渠道" min-width="80">
                                <template slot-scope="{ row }">
                                    <span>
                                        {{ getChannelName(row.channel_id) }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="退款金额"
                                align="center"
                                min-width="80"
                            >
                                <template slot-scope="{ row }">
                                    {{ row.refund_money | $toyun }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="退款状态"
                                align="center"
                                min-width="120"
                            >
                                <template slot-scope="{ row }">
                                    {{
                                        ["未退款", "退款成功", "未知"][
                                            row.refund_status * 1
                                        ]
                                    }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="result_msg"
                                label="退款结果"
                                min-width="150"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="refund_info"
                                label="退款原因"
                                min-width="150"
                            >
                            </el-table-column>
                            <el-table-column label="申请时间" min-width="180">
                                <template slot-scope="{ row }">
                                    {{ row.create_time | $unixTimeToDateTime }}
                                </template>
                            </el-table-column>
                            <el-table-column label="退款时间" min-width="180">
                                <template slot-scope="{ row }">
                                    {{ row.update_time | $unixTimeToDateTime }}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
        <refund-dialog
            :visible.sync="refundDialogVisible"
            :trade-info="tradeInfo"
            @refresh="getDetail"
        ></refund-dialog>
    </div>
</template>

<script>
import { MessageBox } from "element-ui";
import { getOnlineTradeDetail, getOnlineRefundetail } from "@/api/order";
import { isSetSecurityConf } from "@/api/config";
import * as initStore from "@/store/init.js";
import RefundDialog from "./componenets/refundDialog.vue";
export default {
    name: "orderDetail",
    components: {
        RefundDialog,
    },
    data() {
        return {
            loading: false,
            order_id: "",
            order_create_time: "",
            is_refund_order: 0,
            tradeInfo: {}, //交易信息
            refundInfo: [], //退款记录
            notifyInfo: "", //通知信息
            queryLog: "", //通知信息
            payStatusList: {
                0: "未支付",
                1: "已支付",
                2: "已退款",
                3: "部分退款",
            },
            payTypeList: {
                1: "支付宝",
                2: "微信支付",
                3: "云闪付",
                4: "数字人民币",
            },
            hasPwd: true, //是否有二级密码
            refundDialogVisible: false,
        };
    },
    computed: {
        clientList: () => initStore.state.clientList, //客户标识列表
        channelList: () => initStore.state.channelList, //渠道列表
        canRefund() {
            if (!this.tradeInfo) return false;
            if (this.tradeInfo.status == "1" || this.tradeInfo.status == "3") {
                // 已支付 部分退款
                return true;
            }
            return false;
        },
    },
    mounted() {
        const { id, create_time } = this.$route.query;
        this.order_id = id;
        this.order_create_time = create_time;
        this.is_refund_order = this.$route.query.is_refund_order * 1 || 0;
        this.getDetail();
        this.getPwdExist();
    },

    methods: {
        async getDetail() {
            const { order_id, order_create_time } = this;
            const params = {
                [["trade_id", "refund_id"][this.is_refund_order]]: order_id,
                create_time: order_create_time,
            };
            this.loading = true;
            const res = await [getOnlineTradeDetail, getOnlineRefundetail]
                [this.is_refund_order](params)
                .catch((e) => e);
            this.loading = false;
            if (res.code == 200) {
                let { tradeInfo, refundInfo, notifyInfo, queryLog } = res.data;
                if (this.is_refund_order) refundInfo = [refundInfo];
                const refund_money = (refundInfo || []).reduce((total, curr) => {
                    total += curr.refund_money * 1;
                    return total;
                }, 0);
                this.tradeInfo = {
                    ...tradeInfo,
                    rest_money: tradeInfo.total_fee * 1 - refund_money,
                };
                this.refundInfo = refundInfo || [];
                this.notifyInfo = JSON.stringify(notifyInfo) || "";
                this.queryLog = queryLog;
            }
        },
        async getPwdExist() {
            const res = await isSetSecurityConf().catch((e) => e);
            if (res.code == 200) {
                this.hasPwd = !!res.data.security_conf;
            }
        },
        getClientName(client_id) {
            let client_name = "";
            this.clientList.forEach((element) => {
                if (element.client_id == client_id)
                    client_name = element.client_name;
            });
            return client_name;
        },
        getChannelName(channel_id) {
            let channel_name = "";
            this.channelList.forEach((item) => {
                if (item.channel_id == channel_id)
                    channel_name = item.channel_name;
            });
            return channel_name;
        },
        refundEvent() {
            if (!this.hasPwd) {
                MessageBox.confirm(
                    "您还未设置二级密码，无法进行退款，是否前往设置",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                )
                    .then(() => {
                        this.$router.push({
                            name: "secondaryPwdConfig",
                        });
                    })
                    .catch(() => {});
            } else {
                this.refundDialogVisible = true;
                // MessageBox.prompt("", "安全验证", {
                //     confirmButtonText: "确定",
                //     cancelButtonText: "取消",
                //     inputPlaceholder: "请输入二级密码",
                // })
                //     .then(({ value }) => {
                //         this.$message.success("退款成功");
                //     })
                //     .catch(() => {});
            }
        },
    },
};
</script>

<style lang="scss">
@import "@/common/style.scss";
.orderDetail.page {
    overflow: auto;
    .wrap {
        // min-width: 1182px;
        position: relative;
        min-height: 740px;
        border-radius: 2px;
        background: #ffffff;
        padding: 16px 16px 50px 16px;
        .refundMod {
            position: absolute;
            right: 0;
            top: 0;
            padding: 16px;
        }
        .module {
            padding: 20px;
            .mtit {
                font-size: 16px;
            }
            .moduleW {
                padding: 15px;
                p {
                    padding: 5px;
                    line-height: 20px;
                }
                .left {
                    width: 50%;
                }
                .item {
                    word-break: break-all;
                }
            }
        }
    }
    .dialog {
        .dialogList {
            padding: 32px;
            .formRow {
                margin-bottom: 20px;
            }
            .el-form-item {
                margin-bottom: 0;
            }
            .c-money {
                color: #3dba3f;
            }
        }
        .dialog-footer {
            border-top: 1px solid #ebeef5;
            height: 55px;
            padding: 0 16px;
            .dfcancle {
                margin-right: 8px;
                width: 74px;
                height: 32px;
                background: #ffffff;
                border-radius: 2px;
                border: 1px solid #dcdfe6;
                font-size: 14px;
                font-weight: 400;
                color: #565a66;
            }
            .dfconfirm {
                width: 74px;
                height: 32px;
                background: #4d7efa;
                border-radius: 2px;
                border: 1px solid #dcdfe6;
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
            }
        }
    }
}
</style>
