<template>
    <el-dialog
        v-if="visible"
        :visible="visible"
        :show-close="false"
        width="660px"
        :close-on-click-modal="false"
        :destroy-on-close="true"
    >
        <div class="dialog">
            <dialogHeader
                title="补单"
                @closeDialog="handleCancel"
            ></dialogHeader>
            <div class="dialogList">
                <el-form
                    class="refundDialogForm"
                    ref="ruleForm"
                    :model="ruleForm"
                    :rules="rules"
                    label-width="130px"
                >
                    <el-form-item prop="pay_id" label="支付系统订单号：">
                        <el-input v-model="ruleForm.pay_id" disabled></el-input>
                    </el-form-item>
                    <el-form-item prop="trade_no" label="三方订单号：">
                        <el-input v-model="ruleForm.trade_no"></el-input>
                    </el-form-item>
                    <el-form-item prop="buyer_info" label="买家信息：">
                        <el-input
                            v-model="ruleForm.buyer_info"
                            type="textarea"
                            :rows="3"
                            resize="none"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="seller_info" label="卖家信息：">
                        <el-input
                            v-model="ruleForm.seller_info"
                            type="textarea"
                            :rows="3"
                            resize="none"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
                flex="cross:center main:right"
            >
                <el-button size="small" @click="handleCancel(false, $event)">
                    取消
                </el-button>
                <el-button
                    type="primary"
                    size="small"
                    :loading="saveLoading"
                    @click="handleRefund"
                >
                    确定
                </el-button>
            </div>
        </div>
        <el-dialog
            top="20vh"
            width="500px"
            custom-class="pwdConfirmDialog"
            :visible.sync="innerVisible"
            :show-close="false"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            append-to-body
        >
            <dialogHeader
                title="安全验证"
                @closeDialog="innerVisible = false"
            ></dialogHeader>
            <div style="padding: 24px; padding-bottom: 12px">
                <el-input
                    v-focus
                    v-model="password"
                    type="password"
                    placeholder="请输入二级密码"
                ></el-input>
                <p class="c-info">若忘记密码，请联系技术人员重置</p>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
                flex="cross:center main:right"
            >
                <el-button size="small" @click="innerVisible = false">
                    取消
                </el-button>
                <el-button
                    type="primary"
                    size="small"
                    :loading="confirmLoading"
                    @click="handleConfirm"
                >
                    确定
                </el-button>
            </div>
        </el-dialog>
    </el-dialog>
</template>

<script>
import { encode } from "js-base64";
import { supplyOrder } from "@/api/order";
import { checkSecurityConf } from "@/api/config";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        tradeInfo: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            ruleForm: {
                pay_id: "",
                trade_no: "",
                buyer_info: "",
                seller_info: "",
            },
            rules: {},
            saveLoading: false,
            innerVisible: false,
            password: "",
            confirmLoading: false,
        };
    },
    watch: {
        visible(val) {
            if (val) {
                const { pay_id, trade_no, buyer_info, seller_info } =
                    this.tradeInfo;
                this.ruleForm = {
                    pay_id,
                    trade_no,
                    buyer_info,
                    seller_info,
                };
            } else {
                this.ruleForm = {
                    pay_id: "",
                    trade_no: "",
                    buyer_info: "",
                    seller_info: "",
                };
            }
        },
        innerVisible(val) {
            if (!val) {
                this.password = "";
            }
        },
    },
    methods: {
        handleRefund() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    this.innerVisible = true;
                }
            });
        },
        // 取消
        handleCancel(needRefresh = false) {
            this.innerVisible = false;
            this.$emit("update:visible", false);
            needRefresh && this.$emit("refresh");
        },
        async handleConfirm() {
            if (!this.password) return this.$message.error("请输入密码");
            this.confirmLoading = true;
            const checkRes = await checkSecurityConf({
                decipher: encode(this.password),
            }).catch((e) => {
                this.confirmLoading = false;
            });
            if (checkRes.code == 200) {
                const { id, create_time } = this.tradeInfo;
                const { trade_no, buyer_info, seller_info } = this.ruleForm;
                const res = await supplyOrder({
                    trade_id: id,
                    create_time,
                    trade_no,
                    buyer_info,
                    seller_info,
                    decipher: encode(this.password),
                }).catch((e) => {
                    this.confirmLoading = false;
                });
                this.confirmLoading = false;
                if (res.code == 200) {
                    this.handleCancel(true);
                    this.$message.success("补单成功");
                } else {
                    this.$message.error(res.msg);
                }
            }
        },
    },
};
</script>

<style lang="scss">
.refundDialogForm {
    .el-input-number .el-input__inner {
        text-align: left;
    }
}
.pwdConfirmDialog {
    .c-info {
        margin: 8px 0 0 8px;
        font-size: 12px;
        color: #909399;
    }
}
</style>
