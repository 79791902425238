export default {
    payTypeList:[
        {
            id:1,
            payType_name:'支付宝'
        },{
            id:2,
            payType_name:'微信支付'
        },{
            id:3,
            payType_name:'云闪付'
        },{
            id:4,
            payType_name:'数字人民币'
        }
    ]
}

const LOGIN_URL_MAP = {
    local: "http://admin2014.12301.local", //本地
    test: "http://admin2014.12301.test", //内网
    release: "http://admin2014.12301dev.com", //预生产
    gray: "https://admin2014.gray.12301.cc", //灰度
    production: "https://admin2014.12301.cc", //正式
};
const deployEnv = process.env.VUE_APP_DEPLOY_ENV || "production";
export const LOGIN_URL = LOGIN_URL_MAP[deployEnv];