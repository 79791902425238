<template>
    <div class="page logList">
        <page-header :bread-crumb="true" :back-button="true" :sync="true" />
        <div class="wrap">
            <div class="searchBox">
                <el-date-picker @change="onPick" v-model="pickerDates" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" size="small" style="width:246px;margin:0 8px 8px 0;" :clearable="false">
                </el-date-picker>
                <el-select class="space" v-model="searchBox.op_type" placeholder="请选择操作类型" size="small" style="width:180px;">
                    <el-option v-for="(item,key) in op_type" :key="key" :label="item" :value="key">
                    </el-option>
                </el-select>
                <!-- <el-input class="space" v-model="searchBox.operate_id" size="small" style="width:180px;" placeholder="请选择操作员"></el-input> -->
                <el-select size="small" v-model="memberType" placeholder="请选择" style="width:120px;">
                    <el-option v-for="item in memberTypeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-select class="space" size="small" v-model="searchBox.operate_id" filterable remote reserve-keyword :placeholder="`请输入${memberTypeList[memberType].label}搜索用户`" :remote-method="remoteMethod" :loading="selectLoading" style="width:260px;">
                    <el-option v-for="item in memberList" :key="item.id" :label="item.dname" :value="item.id">
                    </el-option>
                </el-select>
                <button class="reset" @click="resetEvent">重置</button>
                <button class="search f14h20w400" @click="searchEvent">搜索</button>
            </div>
            <div class="tableList">
                <!-- 表格部分 -->
                <el-table v-loading="loading" :data="tableData" style="width: 100%" class="table-box" max-height="645" :header-cell-style="{background:'#F5F5F5',color:'#606266'}">
                    <el-table-column label="创建时间" width="180">
                          <template slot-scope="scope">{{scope.row.create_time | $unixTimeToDateTime}}</template>  
                    </el-table-column>
                    <el-table-column label="操作类型" width="150">
                          <template slot-scope="scope">{{op_type[scope.row.op_type]}}</template>  
                    </el-table-column>
                    <el-table-column prop="operate_id" label="操作员ID" width="100"></el-table-column>
                    <el-table-column prop="content" label="日志详情" :show-overflow-tooltip="true"></el-table-column>
                </el-table>
                <!-- 分页 -->
                <div class="page-box ">
                    <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getLogList,getOpType} from "@/api/log"
    import {queryMemberByCondition}  from "@/api/admin"
    import {getToday} from "@/helper/datetime"
    export default {
        name: "logList",
        data() {
            return {
                searchBox: {
                    op_type:'',         //操作类型
                    operate_id:'',      //操作员 id
                    begin_time:'',      //开始时间
                    end_time:''         //结束时间
                },
                op_type:{},             //日志操作类型列表
                tableData:[],
                page:1,
                pageSize:10,
                total:0,
                loading:false,
                getType:'all',   //all==所有 search==搜索 
                pickerDates:[],
                memberTypeList: [{
                        value: 0,
                        label: '账号'
                    },
                    {
                        value: 1,
                        label: '手机号'
                    },
                    {
                        value: 2,
                        label: '名称'
                    },
                ],
                memberType:0,
                memberList:[],
                selectLoading:false,
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init(){
                this.getDate();
                this.getOpType();
                this.getList();
            },
            async getOpType(){
                const res = await getOpType().catch(e => e)
                if(res.code == 200 ){
                    this.op_type = res.data || [];
                }
            },
            getDate(){
                let date = getToday();
                this.pickerDates = [date,date]
                this.searchBox.begin_time = date;
                this.searchBox.end_time = date;
            },
            async getList(){
                if(this.loading) return;
                const {page,pageSize} = this;
                let params = {
                    page:page,
                    page_size:pageSize
                }
                if(this.getType == 'search'){
                    params = Object.assign(params,this.searchBox)
                }else{
                    params.begin_time = this.searchBox.begin_time;
                    params.end_time = this.searchBox.end_time;
                }
                this.loading = true;
                const res = await getLogList(params).catch(e => e).finally(() => {this.loading = false});
                this.loading = false;
                if(res.code == 200){
                    this.tableData = res.data && res.data.list || [];
                    this.total = res.data.total || 0;
                }
            },
            //page改变 促发事件
            handleCurrentChange(val){
                if(this.page == val) return;
                this.page = val;
                this.getList()
            },
            resetEvent(){
                 this.searchBox = {
                    op_type:'',         //操作类型
                    operate_id:'',      //操作员 id
                    begin_time:'',      //开始时间
                    end_time:''         //结束时间
                },
                this.getDate();
                this.getType = 'all';
                this.page = 1;
                this.getList(); 
            },
            searchEvent(){
                this.getType = 'search';
                this.page = 1;
                this.getList();
            },
            //选择时间
            onPick(val){
                this.searchBox.begin_time = val[0];
                this.searchBox.end_time = val[1];
            },
            //输入框检索
            async remoteMethod(val) {
                let pType = {
                    0:'account',
                    1:'mobile',
                    2:'dname',
                }
                let params = {
                    page_size: 100
                }
                params[pType[this.memberType]] = val;
                this.selectLoading = true;
                const res = await queryMemberByCondition(params).catch(e => e).finally(() => {this.selectLoading = false})
                this.selectLoading = false;
                if(res.code != 200) return;
                this.memberList = res.data
            },
        }
    }
</script>
<style lang="scss">
.el-tooltip__popper{
    max-width:600px;
}
</style>
<style scoped lang="scss">
    @import "@/common/style.scss";
    .logList.page {
        overflow-y: auto;
        .wrap {
            min-width: 1182px;
            min-height: 740px;
            border-radius: 2px;
            background: #ffffff;
            padding: 16px 16px 50px 16px;
            .searchBox {
                .space{
                    margin:0 12px 12px 0;
                }
                .reset {
                    margin:0 15px 0 15px;
                    text-align: center;
                    width: 56px;
                    height: 32px;
                    border-radius: 2px;
                    color: #565A66;
                    border: 1px solid #DCDFE6;
                    cursor: pointer;
                }
                .search {
                    text-align: center;
                    width: 56px;
                    height: 32px;
                    background: #4D7EFA;
                    border-radius: 2px;
                    color: #FFFFFF;
                    cursor: pointer;
                }
            }
            .tableList {
                margin-top:15px;
                .page-box {
                    margin-top: 17px;
                    text-align: right;
                }
                .arrowMore{
                    margin-right:12px;
                    fill: #818899;
                }
            }
        }
    }
</style>