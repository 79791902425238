<template>
    <div class="page refundOrder">
        <page-header :bread-crumb="true" :back-button="true" :sync="true" />
        <div class="wrap">
            <div class="searchBox">
                <el-date-picker
                    @change="onPick"
                    v-model="pickerDates"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                    type="datetimerange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    size="small"
                    style="width: 350px; margin: 0 8px 8px 0"
                    :clearable="false"
                >
                </el-date-picker>
                <el-input
                    class="space"
                    v-model="searchBox.out_trade_no"
                    size="small"
                    clearable
                    style="width: 250px"
                    placeholder="请输入原业务系统订单号"
                ></el-input>
                <el-input
                    class="space"
                    v-model="searchBox.trade_no"
                    size="small"
                    clearable
                    style="width: 260px"
                    placeholder="请输入原三方支付订单号"
                ></el-input>
                <el-input
                    class="space"
                    v-model="searchBox.pay_id"
                    size="small"
                    clearable
                    style="width: 200px"
                    placeholder="请输入支付系统订单号"
                ></el-input>
                <button class="more" @click="showMoreEvent">
                    高级搜索
                    <i v-show="!showMore" class="el-icon-arrow-down"></i>
                    <i v-show="showMore" class="el-icon-arrow-up"></i>
                </button>
                <div v-show="showMore" class="moreSearch">
                    <!-- <br /> -->
                    <el-input
                        class="space"
                        v-model="searchBox.batch_no"
                        size="small"
                        clearable
                        style="width: 200px"
                        placeholder="请输入退款批次号"
                    ></el-input>
                    <el-input
                        class="space"
                        v-model="searchBox.refund_id"
                        size="small"
                        clearable
                        style="width: 200px"
                        placeholder="请输入系统退款订单号"
                    ></el-input>
                    <el-select
                        class="space"
                        v-model="searchBox.client_id"
                        placeholder="请选择业务方"
                        size="small"
                        clearable
                        style="width: 180px"
                    >
                        <el-option
                            v-for="item in clientList"
                            :key="item.id"
                            :label="item.client_name"
                            :value="item.client_id"
                        >
                        </el-option>
                    </el-select>
                    <el-select
                        class="space"
                        v-model="searchBox.channel_id"
                        placeholder="请选择渠道"
                        size="small"
                        clearable
                        style="width: 180px"
                    >
                        <el-option
                            v-for="item in channelList"
                            :key="item.id"
                            :label="item.channel_name"
                            :value="item.channel_id"
                        >
                            <span>{{ `[${getChannelId(item.channel_id)}] ${item.channel_name}` }}</span>
                        </el-option>
                    </el-select>
                    <el-select
                        class="space"
                        v-model="searchBox.status"
                        placeholder="请选择订单状态"
                        size="small"
                        style="width: 180px"
                    >
                        <el-option
                            v-for="item in statusBox"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div flex="main:justify cross:center">
                    <div>
                        <button class="search f14h20w400" @click="searchEvent">
                            搜索
                        </button>
                        <button class="reset" @click="resetEvent">重置</button>
                    </div>
                    <button class="search f14h20w400" @click="exportEvent">导出</button>
                </div>
            </div>
            <div class="tableList">
                <!-- 表格部分 -->
                <el-table
                    v-loading="loading"
                    :data="tableData"
                    style="width: 100%"
                    class="table-box"
                    :header-cell-style="{
                        background: '#F5F5F5',
                        color: '#606266',
                    }"
                >
                    <el-table-column
                        prop="out_trade_no"
                        label="业务系统订单号"
                        min-width="180"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="pay_id"
                        label="支付系统订单号"
                        min-width="200"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="refund_id"
                        label="系统退款订单号"
                        min-width="200"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="refund_trade_no"
                        label="三方退款订单号"
                        min-width="200"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="client_name"
                        label="业务方"
                        min-width="120"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="channel_name"
                        label="渠道"
                        min-width="120"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="status_name"
                        label="退款状态"
                        min-width="80"
                    >
                    </el-table-column>
                    <el-table-column
                        label="交易金额"
                        min-width="80"
                        align="center"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.refund_money | $toyun }}
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间" min-width="180">
                        <template slot-scope="scope">
                            {{ scope.row.create_time | $unixTimeToDateTime }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        align="center"
                        fixed="right"
                        width="160"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                style="padding: 0; color: #4d7efa"
                                class="f14h20w400"
                                @click="goDetail(scope.row)"
                            >
                                详情
                            </el-button>
                            <el-button
                                type="text"
                                style="padding: 0; color: #4d7efa"
                                class="f14h20w400"
                                @click="refundEvent(scope.row)"
                            >
                                退款重试
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <div class="page-box">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        :page-size="pageSize"
                        layout="total, prev, pager, next, jumper"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { LOGIN_URL } from "@/common/config";
import { Message } from "element-ui";
import { getToday, getTodayBegin, getTodayEnd } from "@/helper/datetime";
import * as initStore from "@/store/init.js";
import { getOnlineRefundList, addRefundRetry, createExportTask } from "@/api/order";
export default {
    name: "refundOrder",
    data() {
        return {
            searchBox: {
                client_id: "", //业务方标识
                channel_id: "", //渠道编码
                out_trade_no: "", //业务系统订单号
                batch_no: "", //退款批次号
                trade_no: "", //原三方支付订单号
                pay_id: "", //支付系统订单号
                refund_id: "", //系统退款订单号
                status: -1, //订单状态
                begin_time: "", //开始时间
                end_time: "", //结束时间
            },
            statusBox: [
                {
                    id: -1,
                    label: "全部",
                },
                {
                    id: 0,
                    label: "未退款",
                },
                {
                    id: 1,
                    label: "已退款",
                },
            ],
            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,
            loading: false,
            getType: "all", //all==所有 search==搜索
            pickerDates: [],
            showMore: false,
        };
    },
    computed: {
        channelList: () => initStore.state.channelList, //渠道列表
        clientList: () => initStore.state.clientList, //业务方列表
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.getDate();
            // this.getList();
        },
        getDate() {
            let date = getToday();
            let date_begin = getTodayBegin();
            let date_end = getTodayEnd();
            this.pickerDates = [date_begin, date_end];
            this.searchBox.begin_time = date_begin;
            this.searchBox.end_time = date_end;
        },
        getChannelId(id) {
            if (+id < 10) return `0${+id}`;
            return id;
        },
        async getList() {
            const { page, pageSize } = this;
            let params = {
                page: page,
                page_size: pageSize,
            };
            if (this.getType == "search") {
                params = Object.assign(params, this.searchBox);
            } else {
                params.status = this.searchBox.status;
                params.begin_time = this.searchBox.begin_time;
                params.end_time = this.searchBox.end_time;
            }
            this.loading = true;
            const res = await getOnlineRefundList(params)
                .catch((e) => e)
                .finally(() => {
                    this.loading = false;
                });
            console.log(res);
            this.loading = false;
            if (res.code == 200) {
                this.tableData = (res.data && res.data.list) || [];
                this.total = res.data.total || 0;
                //表格数据处理
                this.tableData.forEach((table) => {
                    // table.create_time = $unixTimeToDate(table.create_time);
                    this.channelList.forEach((channel) => {
                        if (channel.channel_id == table.channel_id)
                            table.channel_name = channel.channel_name;
                    });
                    // this.payTypeList.forEach(payType =>{
                    //     if(payType.id == table.pay_type) table.payType_name = payType.payType_name
                    // })
                    this.statusBox.forEach((status) => {
                        if (status.id == table.refund_status)
                            table.status_name = status.label || "未知";
                    });
                    this.clientList.forEach((client) => {
                        if (client.client_id == table.client_id)
                            table.client_name = client.client_name;
                    });
                });
                //表格数据处理
            }
        },
        //选择时间
        onPick(val) {
            this.searchBox.begin_time = val[0];
            this.searchBox.end_time = val[1];
        },
        handleCurrentChange() {},
        //高级搜索事件
        showMoreEvent() {
            this.showMore = !this.showMore;
        },
        //重置事件
        resetEvent() {
            (this.searchBox = {
                client_id: "", //业务方标识
                channel_id: "", //渠道编码
                out_trade_no: "", //原支付订单号
                batch_no: "", //退款批次号
                trade_no: "", //原三方支付订单号
                pay_id: "", //支付系统订单号
                refund_id: "", //系统退款订单号
                status: -1, //订单状态
                begin_time: "", //开始时间
                end_time: "", //结束时间
            }),
                (this.getType = "all");
            this.page = 1;
            this.getList();
        },
        //搜索事件
        searchEvent() {
            this.getType = "search";
            this.page = 1;
            this.getList();
        },
        //导出事件
        async exportEvent() {
            const { pickerArchiveDate, searchBox } = this;
            let params = {
                time_type: pickerArchiveDate,
                ...searchBox,
                judge_type: 98,
            };
            const res = await createExportTask(params).catch(e => e);
            if (res.code == 200) {
                this.$alert(`您好，由于所下载的文件容量较大，已提交至后台进行打包压缩，压缩完毕后可去数据报表中心进行下载，唯一标识符：${res.data}`, '下载提示', {
                    confirmButtonText: '去数据报表中心',
                    callback: action => {
                        if (action == 'confirm') {
                            window.open(`${LOGIN_URL}/new/downloadcenter.html`);
                        }
                    }
                });
            } else {
                Message.error(res.msg);
            }
        },
        //page改变 促发事件
        handleCurrentChange(val) {
            if (this.page == val) return;
            this.page = val;
            this.getList();
        },
        //跳转详情页
        goDetail(val) {
            this.$router.push({
                name: "orderDetail",
                query: {
                    id: val.id,
                    is_refund_order: 1,
                },
            });
        },
        //退款重试
        async refundEvent(val) {
            let text = `订单号“${val.out_trade_no}”退款重试`;
            const result = await this.$helper.default.message
                .confirm({
                    title: `提示`,
                    content: text,
                })
                .catch((e) => e);
            if (result == "cancel") return;
            let params = {
                refund_id: val.id,
            };
            const res = await addRefundRetry(params).catch((e) => e);
            if (res.code != 200) return;
            Message.success("提交成功");
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/common/style.scss";
.refundOrder.page {
    overflow: auto;
    .wrap {
        // min-width: 1182px;
        min-height: 740px;
        border-radius: 2px;
        background: #ffffff;
        padding: 16px 16px 50px 16px;
        .searchBox {
            .space {
                margin: 0 12px 12px 0;
            }
            .more {
                text-align: center;
                width: 96px;
                height: 32px;
                border-radius: 2px;
                color: #565a66;
                border: 1px solid #dcdfe6;
                cursor: pointer;
                margin-bottom: 12px;
            }
            .reset {
                // margin: 0 15px 0 15px;
                margin-left: 15px;
                text-align: center;
                width: 56px;
                height: 32px;
                border-radius: 2px;
                color: #565a66;
                border: 1px solid #dcdfe6;
                cursor: pointer;
            }
            .search {
                text-align: center;
                width: 56px;
                height: 32px;
                background: #4d7efa;
                border-radius: 2px;
                color: #ffffff;
                cursor: pointer;
            }
        }
        .tableList {
            margin-top: 15px;
            .page-box {
                margin-top: 17px;
                text-align: right;
            }
            .arrowMore {
                margin-right: 12px;
                fill: #818899;
            }
        }
    }
}
</style>
