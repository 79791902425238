<template>
    <el-dialog
        v-if="visible"
        :visible="visible"
        :show-close="false"
        width="660px"
        :close-on-click-modal="false"
        :destroy-on-close="true"
    >
        <div class="dialog">
            <dialogHeader
                title="发起退款"
                @closeDialog="handleCancel"
            ></dialogHeader>
            <div class="dialogList">
                <el-form
                    class="refundDialogForm"
                    ref="ruleForm"
                    :model="ruleForm"
                    :rules="rules"
                    label-width="100px"
                >
                    <el-row
                        class="formRow"
                        :gutter="12"
                        type="flex"
                        align="middle"
                    >
                        <el-col :span="18">
                            <el-form-item prop="money" label="退款金额：">
                                <el-input-number
                                    v-model="ruleForm.money"
                                    :controls="false"
                                    :max="restMoneyToYuan"
                                    style="width: 100%"
                                ></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <span>
                                (最多<span class="c-money">{{
                                    restMoney | $formatFenToYuan
                                }}</span
                                >元)
                            </span>
                        </el-col>
                    </el-row>
                    <el-row class="formRow" :gutter="12" type="flex">
                        <el-col :span="18">
                            <el-form-item prop="reason" label="退款原因：">
                                <el-input
                                    v-model="ruleForm.reason"
                                    type="textarea"
                                    maxlength="80"
                                    show-word-limit
                                    :rows="4"
                                    resize="none"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
                flex="cross:center main:right"
            >
                <el-button size="small" @click="handleCancel(false, $event)">
                    取消
                </el-button>
                <el-button
                    type="primary"
                    size="small"
                    :loading="saveLoading"
                    @click="handleRefund"
                >
                    确定
                </el-button>
            </div>
        </div>
        <el-dialog
            top="20vh"
            width="500px"
            custom-class="pwdConfirmDialog"
            :visible.sync="innerVisible"
            :show-close="false"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            append-to-body
        >
            <dialogHeader
                title="安全验证"
                @closeDialog="innerVisible = false"
            ></dialogHeader>
            <div style="padding: 24px; padding-bottom: 12px">
                <el-input
                    v-focus
                    v-model="password"
                    type="password"
                    placeholder="请输入二级密码"
                ></el-input>
                <p class="c-info">若忘记密码，请联系技术人员重置</p>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
                flex="cross:center main:right"
            >
                <el-button size="small" @click="innerVisible = false">
                    取消
                </el-button>
                <el-button
                    type="primary"
                    size="small"
                    :loading="confirmLoading"
                    @click="handleConfirm"
                >
                    确定
                </el-button>
            </div>
        </el-dialog>
    </el-dialog>
</template>

<script>
import NP from "number-precision";
import { encode } from "js-base64";
import { refundOrder } from "@/api/order";
import { checkSecurityConf } from "@/api/config";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        tradeInfo: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            ruleForm: {
                money: undefined,
                reason: "",
            },
            rules: {
                money: [
                    {
                        required: true,
                        message: "请填写退款金额",
                        trigger: "blur",
                    },
                ],
                reason: [
                    {
                        required: true,
                        message: "请填写退款原因",
                        trigger: "blur",
                    },
                ],
            },
            saveLoading: false,
            innerVisible: false,
            password: "",
            confirmLoading: false,
        };
    },
    computed: {
        restMoney() {
            return this.tradeInfo.rest_money || 0;
        },
        restMoneyToYuan() {
            return NP.strip(this.restMoney / 100);
        },
    },
    watch: {
        visible(val) {
            if (!val) {
                this.ruleForm = {
                    money: undefined,
                    reason: "",
                };
            }
        },
        innerVisible(val) {
            if (!val) {
                this.password = "";
            }
        },
    },
    methods: {
        handleRefund() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.innerVisible = true;
                }
            });
        },
        // 取消
        handleCancel(needRefresh = false) {
            this.innerVisible = false;
            this.$emit("update:visible", false);
            needRefresh && this.$emit("refresh");
        },
        async handleConfirm() {
            if (!this.password) return this.$message.error("请输入密码");
            this.confirmLoading = true;
            const checkRes = await checkSecurityConf({
                decipher: encode(this.password),
            }).catch((e) => {
                this.confirmLoading = false;
            });
            if (checkRes.code == 200) {
                const res = await refundOrder({
                    decipher: encode(this.password),
                    trade_id: this.tradeInfo.id,
                    create_time: this.tradeInfo.create_time,
                    refund_info: this.ruleForm.reason,
                    money: this.ruleForm.money * 100,
                }).catch((e) => {
                    this.confirmLoading = false;
                });
                this.confirmLoading = false;
                if (res.code == 200) {
                    this.handleCancel(true);
                    this.$message.success("退款成功");
                } else {
                    this.$message.error(res.msg);
                }
            }
        },
    },
};
</script>

<style lang="scss">
.refundDialogForm {
    .el-input-number .el-input__inner {
        text-align: left;
    }
}
.pwdConfirmDialog {
    .c-info {
        margin: 8px 0 0 8px;
        font-size: 12px;
        color: #909399;
    }
}
</style>
